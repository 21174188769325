import React from "react";
import { Card, Col, Container, Placeholder, Row } from "react-bootstrap";

const Header = () => {
  return (
    <Container className="bgColorDefault mx-0 px-0 pt-2" fluid>
      <Row className="mx-0 py-2 d-flex justify-content-between position-relative border-top border-bottom border-5 border-dark boxShadowBlackSection" style={{height: "100px"}}>
        <Col className="col-5 pe-4 align-content-center text-start">
          <Placeholder as={Card.Img} className="h-100 rounded" />
        </Col>
        <Row className="mx-0 position-absolute h-100 py-0 my-0 top-0">
          <Col
            className="mx-auto col-3 col-md-3 col-xl-2 align-self-center bg-dark h-100 py-0 my-0"
            style={{clipPath: "polygon(25% 0, 100% 0%, 75% 100%, 0% 100%)"}}
          ></Col>
        </Row>
        <Col className="col-5 align-content-center text-end fw-bold h1 py-0 my-0">
          <Col className="text-center textShadowDefault">
            SARGAS
          </Col>
        </Col>
      </Row>
    </Container>);
}
export default Header;