import CardUsluge from "CardUsluge";
import React, { useState } from "react";
import { Col, Container, Row, Image, Button, Placeholder, Card } from "react-bootstrap";
import Gallery from "Gallery";
import towtruckIcon from "../src/ikone/towtruckIcon.svg";
import delivery from "../src/ikone/delivery.svg";
import icon_24h from "../src/ikone/24Icon.svg";
import carIcon from "../src/ikone/carIcon.svg";
import carRepairIcon from "../src/ikone/carRepairIcon.svg";
import carService from "../src/ikone/carService.svg";
import mapLocation from "../src/ikone/mapa.jpg";
import podrucjeDjelovanja from "../src/ikone/podrucjeDjelovanja.jpg";

const Content = () => {
  const [showGallery, setShowGallery] = useState(false);
  const [selectedImageID, setSelectedImageID] = useState(0);

  const images = require.context('../public/slike', false);
  const imagesList = images.keys().map(image => images(image));
  return (
    <Container className="px-0" fluid>
      <Row className="mx-0 bgColorDefault2">
        <Col className="col-auto mx-auto col-xl-7 px-5 py-5">
          <Col className="h1 fw-bold text-center pb-4 textShadowDefault2">
            VUČNA SLUŽBA SARGAS
          </Col>
          <Col className="text-center px-0 px-xl-5">
            <Placeholder as={Card.Img} height={"250px"} className="w-100 rounded" />
          </Col>
        </Col>
        <Col className="col-auto mx-auto col-xl-5 px-5 pb-5 pt-0 pt-lg-5 align-content-center">
          <Col className="h1 pb-3 textShadowDefault2">KONTAKTIRAJTE NAS</Col>
          <Col className="fw-bold fs-3">Kontakt telefon</Col>
          <Col className="fw-bold fs-3">097 609 06 22</Col>
          <Col className="py-3">
            <Button
              href="tel:00385-97-609-06-22"
              className="bgColorDefault border-rounded fw-bold fs-3 py-3 px-5 text-dark btnclass textShadowDefault boxShadowBlack"
            >NAZOVI ODMAH!
            </Button>
          </Col>
        </Col>
      </Row>
      <Row className="mx-0 my-0 py-0 d-flex justify-content-center position-relative border-top border-bottom border-5 border-dark boxShadowBlackSection" style={{ height: "140px" }}>
        <Col className="col-12 h-100 m-0 bgColorDefault" style={{ minHeight: "100%" }}></Col>
        <Col
          className="col-4 col-md-3 col-xl-3 position-absolute align-self-center bg-dark h-100"
          style={{ clipPath: "polygon(25% 0, 100% 0%, 75% 100%, 0% 100%)" }}
        ></Col>
        <Row className="fw-bold fs-1 position-absolute d-flex justify-content-center align-content-center text-center h-100"
          style={{ color: "#babfc4", textShadow: "2px 2px #FFFFFF" }}
        >
          <Col className="col-12 py-0 my-0">DOSTUPNI SMO 0-24</Col>
          <Col className="col-12 py-0 my-0">7 DANA U TJEDNU</Col>
        </Row>
      </Row>
      <Row className="mx-0 py-5 px-3 px-lg-5 bgColorDefault2">
        <Col className="col-12 text-center h1 fw-bold pb-5 textShadowDefault2">USLUGE</Col>
        <CardUsluge icon={towtruckIcon} text="Šlepanje ili vuča raznih vrsta vozila, motocikala, te manjih teretnih vozila" />
        <CardUsluge icon={delivery} text="Prijevoz kamp prikolica, bijele tehnike, radnih strojeva, te ostali teži teret" />
        <CardUsluge icon={carService} text="Zamjena guma, dostava goriva, paljenje vozila busterom, te ukoliko je moguće osposobljavanje za daljnji put" />
        <CardUsluge icon={carRepairIcon} text="Dijagnoza kvara fizička i kompjuterska" />
        <CardUsluge icon={carIcon} text="Mogućnost zamjenskog vozila" />
        <CardUsluge icon={icon_24h} text="Vučna služba 0-24, 7 dana u tjednu" />
      </Row>
      <Row className="mx-0 px-4 py-5 justify-content-center border-top border-bottom border-5 border-dark bgColorDefault boxShadowBlackSection">
        <Row className="mx-0 col-12 h1 pb-5 fw-bold text-center justify-content-center textShadowDefault">PODRUČJE DJELOVANJA</Row>
        <Row className="col-12 col-xl-auto mx-0 mx-xl-auto px-0 pb-5">
          <Col className="col-12 col-md-auto mx-auto align-content-center">
            {
              mapLocation ?
                <>
                  <Image
                    src={podrucjeDjelovanja}
                    height={"350px"}
                    className='rounded object-fit-cover w-100 d-flex d-md-none selectedThumbnail textShadowDefault boxShadowBlack'
                  />
                  <Image
                    src={podrucjeDjelovanja}
                    height={"470px"}
                    className='rounded object-fit-contain w-100 d-none d-md-flex selectedThumbnail textShadowDefault boxShadowBlack'
                  />
                </>
                :
                <Placeholder as={Card.Img} height={"400px"} className='w-100 mx-0 px-0 rounded' />
            }
          </Col>
        </Row>
        <Row className="col-12 col-xl-4 mx-0 mx-xl-auto px-0 pb-5">
          <Row className="mx-0 px-0 ps-xl-3 py-1 d-flex justify-content-center">
            <Col className="col-5 col-xl-3 text-end align-content-center flagIcon">🇪🇺</Col>
            <Col className="col-7 col-xl-9 ps-2 ps-xl-4 text-start fs-2 fw-bold align-content-center">EUROPSKA UNIJA</Col>
          </Row>
          <Row className="mx-0 px-0 ps-xl-3 py-1 d-flex justify-content-center">
            <Col className="col-5 col-xl-3 text-end align-content-center flagIcon">🇭🇷</Col>
            <Col className="col-7 col-xl-9 ps-2 ps-xl-4 text-start fs-2 fw-bold align-content-center">HRVATSKA</Col>
          </Row>
          <Row className="mx-0 px-0 ps-xl-3 py-1 d-flex justify-content-center">
            <Col className="col-5 col-xl-3 text-end align-content-center flagIcon">🇮🇹</Col>
            <Col className="col-7 col-xl-9 ps-2 ps-xl-4 text-start fs-2 fw-bold align-content-center">ITALIJA</Col>
          </Row>
          <Row className="mx-0 px-0 ps-xl-3 py-1 d-flex justify-content-center">
            <Col className="col-5 col-xl-3 text-end align-content-center flagIcon">🇸🇮</Col>
            <Col className="col-7 col-xl-9 ps-2 ps-xl-4 text-start fs-2 fw-bold align-content-center">SLOVENIJA</Col>
          </Row>
          <Row className="mx-0 px-0 ps-xl-3 py-1 d-flex justify-content-center">
            <Col className="col-5 col-xl-3 text-end align-content-center flagIcon">🇧🇦</Col>
            <Col className="col-7 col-xl-9 ps-2 ps-xl-4 text-start fs-2 fw-bold align-content-center">BOSNA I HERCEGOVINA</Col>
          </Row>
          <Row className="mx-0 px-0 ps-xl-3 py-1 d-flex justify-content-center">
            <Col className="col-5 col-xl-3 text-end align-content-center flagIcon">🇷🇸</Col>
            <Col className="col-7 col-xl-9 ps-2 ps-xl-4 text-start fs-2 fw-bold align-content-center">SRBIJA</Col>
          </Row>
        </Row>
      </Row>
      <Row className="mx-0 my-0 py-0 d-flex justify-content-center z-2 position-relative border-top border-bottom border-5 border-dark boxShadowBlackSection" style={{ height: "140px" }}>
        <Col className="col-12 h-100 m-0 bgColorDefault2" style={{ minHeight: "100%" }}></Col>
        <Col
          className="col-4 col-md-3 col-xl-3 position-absolute align-self-center bg-dark h-100"
          style={{ clipPath: "polygon(25% 0, 100% 0%, 75% 100%, 0% 100%)" }}
        ></Col>
        <Row className="fw-bold fs-1 position-absolute d-flex justify-content-center align-content-center text-center h-100"
          style={{textShadow: "2px 2px #FFFFFF" }}
        >
        </Row>
      </Row>
      <Row className="mx-0 px-4 py-5 position-relative z-1 border-top border-bottom border-5 border-dark bgColorDefault boxShadowBlackSection">
        <Row className="mx-0 col-12 h1 pb-5 fw-bold justify-content-center textShadowDefault">GDJE SE NALAZIMO?</Row>
        <Row className="mx-0">
          <Col className="col-12 col-xl-auto mx-auto">
            {
              mapLocation ?
                <>
                  <Image
                    src={mapLocation}
                    height={"450px"}
                    className='rounded object-fit-cover w-100 d-flex d-xl-none selectedThumbnail textShadowDefault boxShadowBlack'
                  />
                  <Image
                    src={mapLocation}
                    height={"600px"}
                    className='rounded object-fit-contain w-100 d-none d-xl-flex selectedThumbnail textShadowDefault boxShadowBlack'
                  />
                </>
                :
                <Placeholder as={Card.Img} height={"400px"} className='w-100 mx-0 px-0 rounded' />
            }
          </Col>
          <Col className="col-12 mt-5 text-center">
            <Col className="h3 fw-bold">Adresa: Vladimira Gortana 6</Col>
            <Col className="h3 fw-bold">Mjesto: 52465, TAR</Col>
          </Col>
        </Row>
        <Row className="mx-0 py-5 justify-content-center">
          <Button
            href="https://www.google.com/maps/place/Rent+A+Car+PLUS/@45.2975809,13.6276775,474m/data=!3m1!1e3!4m6!3m5!1s0x477c9d4d9918614d:0x5f04593da2aae5a1!8m2!3d45.297672!4d13.6276293!16s%2Fg%2F11qn9zyd99?entry=ttu&g_ep=EgoyMDI0MDgyMy4wIKXMDSoASAFQAw%3D%3D"
            target="_blank"
            className="border-rounded fw-bold fs-3 py-3 mt-0 mt-xl-4 px-5 text-dark w-auto btnclass textShadowDefault boxShadowBlack bgColorDefault2"
            >PRIKAŽI NA GOOGLE MAPS</Button>
        </Row>
      </Row>
      <Row className="mx-0 px-4 px-md-2 py-5 bgColorDefault2">
        <Col className="col-auto mx-auto h1 pb-4 fw-bold textShadowDefault2">GALERIJA</Col>
        <Row className='col-12 mx-0 px-0'>
          {
            imagesList && imagesList.length > 0 ?
              imagesList.filter((ele, index) => index < 6).map((ele, index) => (
                <Col key={"i" + index} className='col-12 col-md-6 col-xl-4 py-3 px-0 px-md-3'>
                  <Col>
                    <Image
                      src={ele}
                      height={"425px"}
                      className='rounded object-fit-cover w-100'
                      style={{ boxShadow: "0px 0px 10px 3px #ff0000" }}
                      onClick={() => {
                        setSelectedImageID(ele);
                        setShowGallery(true);
                      }}
                    />
                  </Col>
                </Col>
              ))
              :
              Array.from({ length: 9 }, (ele, index) => (
                <Col key={"i_p_" + index} className='col-12 col-md-6 col-xl-4 py-4 px-0 px-md-4'>
                  <Row className="mx-0">
                    <Placeholder as={Card.Img} height={"200px"} className='w-100 mx-0 px-0 rounded' />
                  </Row>
                </Col>
              ))
          }
        </Row>
        <Row className='col-auto mx-auto px-0 pt-5 pb-3'>
          <Button
            className="bgColorDefault border-rounded fw-bold fs-3 py-3 px-5 text-dark btnclass textShadowDefault boxShadowBlack"
            onClick={() => {
              setSelectedImageID(imagesList[0]);
              setShowGallery(true);
            }}
          >PRIKAŽI SVE SLIKE
          </Button>
        </Row>
        <Row>
          <Gallery
            imagesRef={imagesList}
            showGallery={showGallery}
            setShowGallery={setShowGallery}
            showImageByID={selectedImageID}
            setSelectedImageID={setSelectedImageID} />
        </Row>
      </Row>
    </Container>);
}
export default Content;