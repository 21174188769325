import React from 'react';
import { Container } from 'react-bootstrap';
import Footer from 'Footer';
import Header from 'Header';
import Content from 'Content';

function App() {
  return (
    <Container className='mx-0 px-0 w-100' fluid>
      <Header />
      <Content />
      <Footer />
    </Container>
  );
}

export default App;
