import React from "react";
import { Card, Col, Placeholder, Image, Row } from "react-bootstrap";

const CardUsluge = (props) => {
  return (
    <Row className="mx-0 px-0 col-12 col-xl-6 pb-5 px-md-3">
      <Col className="col-12 text-center align-content-center pb-1 d-block d-md-none">
        {props.icon ?
          <Image src={props.icon} height={"100px"} style={{ maxWidth: "200px" }} className="w-100 object-fit-scale " />
          :
          <Placeholder as={Card.Img} height={"100px"} className="w-100 rounded" />
        }
      </Col>
      <Col className="col-12 text-center align-content-center fs-5 fw-bold d-block d-md-none">{props.text}</Col>
      <Col className="col-4 text-end align-content-center pb-1 d-none d-md-block">
        {props.icon ?
          <Image src={props.icon} height={"80px"} style={{ maxWidth: "170px" }} className="w-auto object-fit-contain " />
          :
          <Placeholder as={Card.Img} height={"100px"} className="w-100 rounded" />
        }
      </Col>
      <Col className="col-8 text-start align-content-center fs-4 fw-bold d-none d-md-block">{props.text}</Col>
    </Row>
  );
}
export default CardUsluge;